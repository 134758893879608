<template>
    <div class="detail_luk">
        <!-- 整体录制 -->
        <div class="content">
            <video
                ref="video"
                :src="detail.videoPath"
                :poster="detail.sharImage"
                @pause="onPause"
                @play="onPlay"
                controls
            ></video>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        detail: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        onPause () {
            this.$emit('onPause')
        },
        onPlay () {
            this.$emit('onPlay')
        },
    }
}
</script>
