<template>
	<div class="work_detail">
		<div class="user_info"
				 v-if="detail.doctorDTO">
			<div class="user_info_avatar">
				<img class="user_info_avatar__inner"
						 :src="detail.doctorDTO.avatar"
						 alt="" />
			</div>
			<div class="user_info_content">
				<p class="user_info_content_name">
					{{ detail.doctorDTO.name }}
					<span class="user_info_content_name_desc">{{ detail.doctorDTO.deptName }} |
						{{ detail.doctorDTO.title }}</span>
				</p>
				<p class="user_info_content_hospital">
					{{ detail.doctorDTO.hospitalName }}
				</p>
			</div>
		</div>
		<div class="publish_time_bar">
			<div>发布于{{ detail.updateTime.slice(0, 10) }}</div>
			<div>
				{{ $route.query.heatNum || detail.browseNumber }}
				浏览
			</div>
		</div>
		<h2 class="title">{{ detail.title }}</h2>

		<div class="label">
			<p v-for="(item, index) in detail.labelList"
				 :key="`label-${index}`"
				 class="tab">
				<img class="tab__icon"
						 src="@/img/icon-tag.png"
						 alt="" />
				<span>{{ item.label }}</span>
			</p>
		</div>

		<!-- 录课作品详情 -->
		<detail-i-fenshen :detail="detail"
											@onPause="onPause"
											@onPlay="onPlay" />
	</div>
</template>

<script>
import DetailIFenshen from "./components/detail-i-fenshen.vue";
import buriedPoint from "../../utils/mixins/buriedPoint";

export default {
	components: { DetailIFenshen },
	data: () => ({
		detail: {},
		TimerBuriedPointPlaying: null,
		app_name: "",
		showPopover: false,
	}),
	mixins: [buriedPoint],
	computed: {
		md_send_path() {
			return "work_detail";
		},
		md_send_params() {
			const { id, title } = this.detail;
			let live_type = 'i-fenshen-mobile', content_type = "video";

			return {
				live_id: id,
				live_name: title,
				live_type,
				content_type,
				content_id: id,
			};
		},
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		async getDetail() {
			const res = await this.$api.work.ifenshenInfoWork(this.$route.params.id);
			if (!res.code) {
				this.detail = res.data;
				document.title = res.data.title;
				//发送进入埋点
				this.entryPage();
			}
		},
		onPause() {
			console.log("pause");
			this.pauseBuriedPoint();
		},
		onPlay() {
			console.log("playing");
			this.playingBuriedPoint();
		},
	},
};
</script>
<style lang="less">
.publish_time_bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: PingFang SC;
	font-size: 0.8rem;
	color: #bbbfc4;
	margin-top: 0.8rem;
}

.detail_creation {
	.content_video {
		width: 100%;
	}
}

.van-popover__action-text {
	text-align: center;
}
</style>
