/**
 * 在需要使用的页面computed中加入
 * md_project_name
 * md_send_path
 * md_send_params
 */

export default {
	data: () => ({
		buriedPointPlayingTimer: null,
	}),
	computed: {
		getOpenid() {
			return window.localStorage.getItem("openid");
		},
		getUnionId() {
			return window.sessionStorage.getItem("unionId");
		},
	},
	async created() {
		await this.load();
	},
	methods: {
		// 页面初始化埋点数据
		async load() {
			this.$buriedPoint.updateInitData({
				project_name: this.md_project_name,
			});

			if (this.getOpenid) {
				this.$buriedPoint.setUserInfo({
					user_unique_id: this.getUnionId || this.getOpenid, //7月4日增加unionId 满足不同公众号推送识别同一用户
					user_id: this.getOpenid,
					user_type: 0,
				});
			}

			console.log(this.$buriedPoint);
		},
		//  发送埋点
		basePushFunc(type, needSend) {
			if (this.getOpenid) {
				this.$buriedPoint.push({
					event_type: type,
					event_ts: Date.parse(new Date()),
					path: this.md_send_path,
					params: this.md_send_params,
				});
				if (needSend) {
					this.$buriedPoint.send();
				}
			}
		},
		//进入页面
		entryPage() {
			this.basePushFunc("entry_page", true);
		},

		/**
		 * 定时上报
		 * 针对于视频类的
		 */
		playingBuriedPoint() {
			this.startBuriedPointPushPlaying();
			this.buriedPointPlayingTimer = setInterval(
				this.startBuriedPointPushPlaying,
				20 * 1000
			);
		},
		// 上报定时器回调
		startBuriedPointPushPlaying() {
			this.basePushFunc("playing", true);
		},
		// 暂停事件
		pauseBuriedPoint() {
			this.clearFunc();
			this.basePushFunc("pause", true);
		},
		// 离开页面
		deactivated() {
			this.basePushFunc("leave_page", true);
		},
		clearFunc() {
			if (this.buriedPointPlayingTimer) {
				clearInterval(this.buriedPointPlayingTimer);
			}
		},
	},
	destroyed() {
		this.clearFunc();
		this.deactivated();
	},
};
